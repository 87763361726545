var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#FF681F","spinner":"spinner"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"rgba(255, 98, 0, 1)","right":"","top":"","timeout":"2000"},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":"","align-self-center":""}},[_c('span',{staticClass:"title1",style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '25px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '25px'
                  : '30px',
            })},[_vm._v("Edit Hotel Details")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-space-around":"","pt-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"tile":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-tabs',{ref:"tabs",staticClass:"nsbold",staticStyle:{"font-size":"16px"},attrs:{"background-color":"#002635","dark":"","touchless":"","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v("Overview")]),_c('v-tab',[_vm._v("Location")]),_c('v-tab',[_vm._v("Amenities")]),_c('v-tab',[_vm._v("Policies")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',{attrs:{"touch":false}},[_c('v-card',{attrs:{"outlined":"","pa-2":""}},[_c('overiewPageVue',{attrs:{"hotelData":_vm.hotelData,"facilitiesData":_vm.FacilitiesData},on:{"next-tab":_vm.nextTab}})],1)],1),_c('v-tab-item',{attrs:{"touch":false}},[_c('v-card',{attrs:{"outlined":"","pa-2":""}},[_c('AddLocation',{attrs:{"hotelData":_vm.hotelData,"facilitiesData":_vm.FacilitiesData,"hotelId":_vm.id},on:{"next-tab":_vm.nextTab}})],1)],1),_c('v-tab-item',{attrs:{"touch":false}},[_c('v-card',{attrs:{"outlined":"","pa-2":""}},[_c('AddAmenities',{attrs:{"hotelData":_vm.hotelData,"facilitiesData":_vm.FacilitiesData,"hotelId":_vm.id},on:{"next-tab":_vm.nextTab}})],1)],1),_c('v-tab-item',{attrs:{"touch":false}},[_c('v-card',{attrs:{"outlined":"","pa-2":""}},[_c('AddPolicies',{attrs:{"hotelData":_vm.hotelData,"facilitiesData":_vm.FacilitiesData,"hotelId":_vm.id},on:{"next-tab":_vm.nextTab}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }