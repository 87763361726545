<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF681F"
      spinner="spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar
      v-model="showSnackBar"
      color="rgba(255, 98, 0, 1)"
      right
      top
      timeout="2000"
    >
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pa-4>
      <v-flex xs12>
        <v-layout wrap justify-start py-2>
          <v-flex xs12 sm3 align-self-center>
            <span
              class="title1"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '25px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '25px'
                    : '30px',
              }"
              >Edit Hotel Details</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-space-around pt-2>
          <v-flex xs12>
            <v-card tile>
              <v-layout wrap justify-space-around>
          <v-flex xs12>
            <v-tabs
              background-color="#002635"
              dark
              touchless
              grow
              ref="tabs"
              class="nsbold"
              style="font-size: 16px"
              v-model="activeTab"
            >
              <v-tab >Overview</v-tab>
              <v-tab >Location</v-tab>
              <v-tab >Amenities</v-tab>
              <v-tab >Policies</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab" touchless>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <overiewPageVue :hotelData="hotelData" :facilitiesData="FacilitiesData" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddLocation :hotelData="hotelData" :facilitiesData="FacilitiesData" :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddAmenities :hotelData="hotelData" :facilitiesData="FacilitiesData" :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddPolicies :hotelData="hotelData" :facilitiesData="FacilitiesData" :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

  </div>
</template>
    <script>
import axios from "axios";
import overiewPageVue from "./overiewPage.vue";
import AddLocation from "./AddLocation.vue";
import AddAmenities from "./AddAmenities.vue";
import AddPolicies from "./AddPolicies.vue";
export default {
  components: {
    overiewPageVue,
    AddLocation,
    AddAmenities,
    AddPolicies,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      hotelData: [],
      FacilitiesData: [],
      msg: null,
      page: 1,
      limit: 20,
      activeTab: 0,
      id: null,
      form1Data: {
        name: "",
        contact: "",
      },
      form2Data: {
        name: "",
        contact: "",
      },
      savedTabs: [false, false, false, false, false, false],
   
    };
  },
  mounted() {
    this.getData();

    
    this.disableSwipe();
    if (window.innerWidth <= 600) {
      const tabs = document.querySelector(".v-tabs");
      if (tabs) {
        tabs.addEventListener(
          "touchmove",
          (e) => {
            e.preventDefault();
          },
          { passive: false }
        );
      }
    }
  },
  methods: {
    disableSwipe() {
      const tabs = this.$refs.tabs;
      if (tabs) {
        tabs.addEventListener("touchstart", this.preventTouch, {
          passive: false,
        });
        tabs.addEventListener("touchmove", this.preventTouch, {
          passive: false,
        });
      }
    },
    preventTouch(event) {
      event.preventDefault();
    },
    // handleIdReceived(id) {
    //   this.id = id;
    //   this.nextTab();
    // },
    handleIdReceived(id) {
  this.id = id;
  console.log("ttttt")
  this.fetchAndProceed(); // Fetch data and then proceed to the next tab
},
fetchAndProceed() {
    this.getData().then(() => {
      this.nextTab(); // Move to the next tab after data is fetched
    });
  },
  nextTab() {
    if (this.activeTab < 3) {
      this.activeTab += 1; // Move to the next tab
    }
  },
    // nextTab() {
    //   if (this.activeTab < 3) {
    //     this.activeTab += 1; // Move to the next tab
    //   }
    // },
    getData() {
      this.appLoading = true;
      axios({
        url: "/vendor/hotel/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.hotelData = response.data.data;
          this.FacilitiesData = this.hotelData.facilities;
       
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>