<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Policies</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 fill-height>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-right>
            <v-text-field
              dense
              v-model="newPolicy"
              outlined
              type="text"
              hide-details="auto"
            ></v-text-field>
            <span class="title2" style="color: orange" @click="addPolicies()"
              >Create <v-icon color="orange">mdi-plus</v-icon></span
            >
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <v-layout wrap v-if="localHotelData.policies.length > 0">
            <v-flex
              xs12
              pt-4
              v-for="(policy, index) in localHotelData.policies"
              :key="index"
            >
              <v-layout wrap>
                <v-flex xs11>
                  <v-text-field
                    dense disabled
                    hide-details
                    v-model="localHotelData.policies[index]"
                    outlined
                    type="text"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs1 align-self-center text-center>
                  <v-icon @click="deletePolicies(index)"> mdi-delete </v-icon>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pb-8>
          <v-flex xs12 sm10 pt-4 text-left>
            <v-layout wrap justify-end>
              <v-flex xs12 sm5>
                <v-btn
                  block
                  @click="add()"
                  class="btnstly"
                  style="cursor: pointer"
                  ><span style="font-family: LexendFont; text-transform: none"
                    >Save</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ['hotelData', 'facilitiesData', 'hotelId'],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      data: [],
      msg: null,
      newPolicy: "",
      Policies: [],
      localHotelData: { ...this.hotelData },  
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  methods: {
    addPolicies() {
      if (this.newPolicy) {
        this.localHotelData.policies.push(this.newPolicy);
        this.newPolicy = ""; // Reset the new policy field
      }
    },
    deletePolicies(index) {
      this.localHotelData.policies.splice(index, 1); // Remove the policy at the given index
    },
    add() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/hotel/edit/policy",
        data: {
          policy: this.localHotelData.policies,
          hotelId: this.localHotelData._id
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$router.push('/HotelDetailedView?id='+this.$route.query.id)
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>